import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { WorkspaceUser } from '@app/_classes/timeghost';
import { ElRefDirective } from '@app/_directives/el-ref/el-ref.directive';
import { ObserveFormGroupErrors, useFormErrorObservable } from '@app/_helpers/get-error-observable';
import { pushError } from '@app/_helpers/globalErrorHandler';
import {
  ErrorResponse,
  isBreakRuleError,
  isWorkingHoursError,
  parseErrorResponse,
} from '@app/_helpers/is-error-object';
import parseSubscriptionAsStatus from '@app/_helpers/parseSubscriptionAsStatus';
import { isAdmin, isSupervisor } from '@app/_helpers/permission';
import { ComegoTimeDate } from '@app/_helpers/types';
import {
  DEFAULT_PERMISSION_GROUPS,
  coerceTimeFormat,
  createRxValue,
  distinctUntilChangedJson,
  fromRxValue,
  getActiveSchedule,
  hasPermission,
  hoursToFormat,
  nextTick,
  resolveRawArgs,
  stringify,
} from '@app/_helpers/utils';
import { SameValueError } from '@app/_validators/custom-validators';
import { AppService } from '@app/app.service';
import { RecordToolbarService } from '@app/shared/record-toolbar/record-toolbar.service';
import { TimeDatePickerConfig } from '@app/shared/time-date-picker/time-date-picker-config';
import { TimeDatePickerComponent } from '@app/shared/time-date-picker/time-date-picker.component';
import { Order } from '@datorama/akita';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  addDays,
  addMilliseconds,
  addMinutes,
  areIntervalsOverlapping,
  clamp as clampDate,
  endOfDay,
  format,
  isSameDay,
  isSameMinute,
  isValid,
  roundToNearestMinutes,
  startOfDay,
} from 'date-fns/esm';
import { pick } from 'lodash-es';
import { combineLatest, distinctUntilChanged, filter, map, startWith, takeWhile, tap } from 'rxjs';
import {
  ComegoQuery,
  ComegoService,
  ComegoTime,
  ComegoTimeType,
  Logger,
  UserSettings,
  UserSettingsQuery,
  WorkingHourSettings,
  Workspace,
} from 'timeghost-api';
import {
  UserSinglePickerDialogComponent,
  UserSinglePickerDialogData,
} from '../user-single-picker-dialog/user-single-picker-dialog.component';
import { COMEGO_ICON_MAP, COMEGO_MAX_DESCRIPTION } from './come-and-go-utils';

export type ComeGoTime = Omit<ComegoTime, 'workspace' | 'id'>;
export type ComeGoTimeControl = { [K in keyof Omit<ComeGoTime, 'name'>]: FormControl<ComeGoTime[K]> };
export type TimesControl = FormGroup<ComeGoTimeControl>;
const parseTimeControl = ({ start: nstart, end: nend }: ComeGoTime, date?: Date) => {
  const start = nstart && coerceTimeFormat(nstart, date ?? new Date());
  const end = nend && coerceTimeFormat(nend, date ?? new Date());
  return { start, end };
};
export type CheckTimeOrderOptions = {
  treatAbsenceAsWorktime: boolean;
};
export const checkTimeOrder: (
  user: () => UserSettings,
  sumBase?: () => { [K in ComegoTimeType]: number },
  getLastTime?: () => ComegoTimeDate,
  getNextTime?: () => ComegoTimeDate,
  options?: Partial<CheckTimeOrderOptions>,
) => ValidatorFn = (_user, sumBase, getLastTime, getNextTime, options) => {
  const user = _user();
  return (form: FormArray<TimesControl>) => {
    if (!form) return null;
    if (!form.length)
      return {
        required: true,
      };
    const value = form.getRawValue();
    const baseSum = sumBase?.();
    let sumValue = (baseSum && baseSum.work + ((options?.treatAbsenceAsWorktime && baseSum.absence) || 0)) || 0;
    let pauseSum = (baseSum && baseSum.pause) || 0;
    for (let i = 0; i < value.length; i++) {
      const x = value[i];
      const dstart = coerceTimeFormat(x.start),
        dend = coerceTimeFormat(x.end);
      if (!isValid(dstart) || !isValid(dend))
        return {
          required: true,
          index: i,
        };
      if (isSameMinute(dstart, dend))
        return {
          same: true,
          fields: {
            field: 'start',
            field2: 'end',
          },
          index: i,
        } as SameValueError;
      if (dstart > dend)
        return {
          intervening: true,
          index: i,
        };
      const itemSum = dend.getTime() - dstart.getTime();
      if (x.type !== 'pause' && ((options?.treatAbsenceAsWorktime && x.type === 'absence') || x.type === 'work'))
        sumValue += itemSum;
      else pauseSum += itemSum;
      if (value.length > 1) {
        for (let di = 0; di < value.length; di++) {
          if (di === i) continue;
          const d = value[di];
          const start = coerceTimeFormat(d.start),
            end = coerceTimeFormat(d.end);
          if (!isValid(start) || !isValid(end))
            return {
              required: true,
              index: di,
            };
          if (isSameMinute(start, end))
            return {
              same: true,
              fields: {
                field: 'start',
                field2: 'end',
              },
              index: di,
            } as SameValueError;
          if (start > end)
            return {
              intervening: true,
              index: di,
            };
          if (areIntervalsOverlapping({ start: dstart, end: dend }, { start, end }))
            return {
              intervening: true,
              index: di,
            };
        }
      }
    }
    if (!user?.workspace?.schedules?.workspace) return null;
    const wh: WorkingHourSettings = user.workspace.schedules.workspace['workinghours'];
    if (!wh) return null;
    if (wh.preventDeviatingWorkingHours) {
      if (typeof wh.dailyMaxHours === 'number' && sumValue > wh.dailyMaxHours * 3600 * 1000) {
        return {
          breakRule: {
            type: 'maxHours',
            valueMs: wh.dailyMaxHours * 3600 * 1000,
            value: hoursToFormat(wh.dailyMaxHours),
          },
        };
      }

      if (typeof wh.breakBetweenWorkingDays === 'number' && wh.breakBetweenWorkingDays > 0.0) {
        const wtimes = value
          ?.filter((x) => x.type === 'work')
          .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());
        const firstWork = wtimes?.[0];
        const lastWork = wtimes?.[wtimes.length - 1];
        const date: Date = form.root.value.date!;
        const now = new Date();
        const tstart =
          (firstWork?.start && coerceTimeFormat(firstWork?.start, date)) || (isSameDay(date, now) ? now : null);
        const tend = (lastWork?.end && coerceTimeFormat(lastWork.end, date)) || (isSameDay(date, now) ? now : null);
        if (tstart) {
          const lastWorkingDayTime = getLastTime?.();
          const nextWorkingDayTime = getNextTime?.();
          const minBreakBetweenDay = wh.breakBetweenWorkingDays * 3600 * 1000;
          if (
            (lastWorkingDayTime?.end &&
              tstart.getTime() - new Date(lastWorkingDayTime.end ?? tstart.getTime()).getTime() < minBreakBetweenDay) ||
            (nextWorkingDayTime?.start &&
              new Date(nextWorkingDayTime.start ?? tend.getTime()).getTime() - tend.getTime() < minBreakBetweenDay)
          ) {
            return {
              breakRule: {
                type: 'rest',
                value: hoursToFormat(wh.breakBetweenWorkingDays),
              },
            };
          }
        }
      }
      if (wh.breaks?.length) {
        const breakRule = wh.breaks
          .slice()
          .sort((b, a) => a.workingHours - b.workingHours)
          .find((x) => {
            const workingHourMs = x.workingHours * 3600 * 1000;
            const pauseHourMs = x.minutesDue * 3600 * 1000;
            return workingHourMs < sumValue && pauseHourMs > pauseSum;
          });
        if (breakRule)
          return {
            breakRule: {
              ...resolveRawArgs(breakRule),
              type: 'break',
              value: sumValue,
              rule: breakRule,
            },
          };
      }
    }
    // if (typeof wh["breakBetweenWorkingDays"] === 'number' && sumValue > wh["breakBetweenWorkingDays"] * 3600 * 1000) {
    //   return {
    //     breakRule: {
    //       type: 'maxHours',
    //       value: wh["breakBetweenWorkingDays"] * 3600 * 1000,
    //     },
    //   };
    // }
    return null;
  };
};
export function generateTimeTableFromSchedule(user: UserSettings, now: Date = new Date()) {
  const schedule = getActiveSchedule(user, now);
  if (!schedule?.enabled || !schedule.items?.length) return null;
  const day = schedule.items?.find((x) => x.enabled && x.day === startOfDay(now).getUTCDay());
  if (!day) return null;
  const times =
    day.items?.reduce((acc, r, i, arr) => {
      const isLast = i === arr.length - 1;
      const nextItem = arr[i + 1];
      const addPauseItem = nextItem && r.to !== nextItem.from;
      acc.push({
        name: 'comego.work',
        start: r.from,
        end: r.to,
        type: 'work',
      } as any);
      if (addPauseItem)
        acc.push({
          name: 'comego.pause',
          start: r.to,
          end: nextItem.from,
          type: 'pause',
        } as any);
      return acc;
    }, [] as ComeGoTime[]) || [];
  return times;
}
const log = new Logger('ComeAndGoCreateDialogComponent');
export interface WorkingHoursCreateData {
  time?: {
    value: ComegoTime;
    correctTimeRanges?: boolean;
    deleteOriginTime?: boolean;
    lockItem?: boolean;
  };
  initialTimes?: { start: Date; end: Date; type: ComegoTimeType }[];
  error?: ErrorResponse;
  hiddenFields?: string[];
  disabledFields?: string[];
  allowedTimeTypes?: ComegoTimeType[];
  disabled?: boolean;
}
@UntilDestroy()
@ObserveFormGroupErrors()
@Component({
  selector: 'tg-come-and-go-create-dialog',
  templateUrl: './come-and-go-create-dialog.component.html',
  styleUrls: ['./come-and-go-create-dialog.component.scss'],
})
export class ComeAndGoCreateDialogComponent implements OnInit {
  readonly iconMap = COMEGO_ICON_MAP;
  readonly error$ = createRxValue<ErrorResponse>();
  constructor(
    private ref: MatDialogRef<ComeAndGoCreateDialogComponent>,
    private dialog: MatDialog,
    private userSettingsQuery: UserSettingsQuery,
    private app: AppService,
    private comegoService: ComegoService,
    private comegoQuery: ComegoQuery,
    private recordService: RecordToolbarService,
    @Inject(MAT_DIALOG_DATA)
    private data: WorkingHoursCreateData,
  ) {
    if (!this.data) this.data = {};
  }
  readonly isLoading = createRxValue(false);
  readonly maxLength = COMEGO_MAX_DESCRIPTION;
  readonly group = new FormGroup({
    name: new FormControl<string>(null, [Validators.maxLength(this.maxLength)]),
    user: new FormControl<Workspace['users'][0]>(null),
    times: new FormArray<TimesControl>([], {
      validators: [
        checkTimeOrder(
          () => this.userSettingsQuery.getValue(),
          () => {
            const date = this.group?.getRawValue()?.date as Date;
            if (!date || !isValid(date)) return null;
            const currentUser = this.group?.getRawValue()?.user as WorkspaceUser;
            const user = {
              ...this.userSettingsQuery.getValue(),
              ...((currentUser?.id && { id: currentUser?.id }) || {}),
            };
            const sum = this.comegoQuery
              .getAll({ filterBy: (x) => isSameDay(new Date(x.start), date) && x.user?.id === user.id })
              .reduce(
                (acc, r) => {
                  acc[r.type] += new Date(r.end ?? date).getTime() - new Date(r.start).getTime();
                  return acc;
                },
                {
                  absence: 0,
                  pause: 0,
                  work: 0,
                } as { [K in ComegoTimeType]: number },
              );
            return sum;
          },
          () => {
            const { date } = this.group?.getRawValue() || {};
            if (!date || !isValid(date)) return null;
            const currentUser = this.group?.getRawValue()?.user as WorkspaceUser;
            const user = {
              ...this.userSettingsQuery.getValue(),
              ...((currentUser?.id && { id: currentUser?.id }) || {}),
            };
            const pastDay = addDays(date.getTime(), -1);
            const lastWorkingDayTime = this.comegoQuery.getAll({
              filterBy: (x) => isSameDay(new Date(x.start), pastDay) && x.user?.id === user.id,
              limitTo: 1,
              sortBy(a, b) {
                const aend = new Date(a.end || new Date()),
                  bend = new Date(b.end || new Date());
                return aend.getTime() - bend.getTime();
              },
              sortByOrder: Order.DESC,
            })?.[0];
            if (!lastWorkingDayTime) return null;
            return {
              ...lastWorkingDayTime,
              start: new Date(lastWorkingDayTime.start),
              end: new Date(lastWorkingDayTime.end || new Date()),
            };
          },
          () => {
            const { date } = this.group?.getRawValue() || {};
            if (!date || !isValid(date)) return null;
            const user = this.userSettingsQuery.getValue();
            const dayOfTime = addDays(date.getTime(), 1);
            const nextWorkingDayTime = this.comegoQuery.getAll({
              filterBy: (x) => isSameDay(new Date(x.start), dayOfTime) && x.user?.id === user.id,
              limitTo: 1,
              sortBy(a, b) {
                const atime = new Date(a.start || new Date()),
                  btime = new Date(b.start || new Date());
                return atime.getTime() - btime.getTime();
              },
              sortByOrder: Order.ASC,
            })?.[0];
            if (!nextWorkingDayTime) return null;
            return {
              ...nextWorkingDayTime,
              start: new Date(nextWorkingDayTime.start),
              end: new Date(nextWorkingDayTime.end || new Date()),
            };
          },
        ),
      ],
      updateOn: 'blur',
    }),
    date: new FormControl<Date>(new Date()),
  });
  readonly duration$ = this.group.valueChanges.pipe(
    startWith(this.group.getRawValue()),
    map(() => {
      const x = this.group.getRawValue();
      return x.times.reduce(
        (acc, r) => {
          if (!r.start || !r.end) return acc;
          const start = coerceTimeFormat(r.start!).getTime(),
            end = coerceTimeFormat(r.end!).getTime();
          if (end < start) return acc;
          const sum = (end - start) / 1000;
          if (r.type === 'work') acc.working += sum;
          else if (r.type === 'pause') acc.pause += sum;
          else if (r.type === 'absence') acc.absence += sum;
          return acc;
        },
        { working: 0, pause: 0, absence: 0 },
      );
    }),
  );
  readonly workspace$isAdmin = fromRxValue(
    this.userSettingsQuery.select((x) => hasPermission(DEFAULT_PERMISSION_GROUPS.Admin, x)),
  );
  readonly workspace$canManageOthers = fromRxValue(this.userSettingsQuery.select((x) => isAdmin(x) || isSupervisor(x)));
  getErrorObservable(controlName: string) {
    const aliasMap = {
      name: 'time.name',
      start: 'timer.time.start',
      end: 'timer.time.end',
    };
    const aliasName = aliasMap[controlName] || controlName;
    return useFormErrorObservable(this, { respectState: true })(
      controlName,
      () => this.group.controls[controlName],
      {
        required: (error, ctrl) => {
          if (controlName === 'task') return { content: 'errors.record.desc-required', args: {} };
          if (controlName === 'project') return { content: 'errors.record.project-req', args: {} };
          if (controlName === 'times') return { content: 'errors.times.comego.required', args: {} };
          return {
            content: 'errors.required',
            args: { field: aliasName },
          };
        },
        same: (error, ctrl) => {
          const aliasFields = Object.entries(ctrl.errors?.fields || {}).reduce(
            (acc, [key, value]) => ({ ...acc, [key]: aliasMap[value as string] }),
            {},
          );
          return { content: 'errors.sameValue', args: { ...aliasFields } };
        },
        breakRule: (error) => {
          if (error.type === 'break')
            return { content: 'errors.times.comego.breakRuleNotice', args: pick(error, 'workingHours', 'minutesDue') };
          if (error.type === 'rest')
            return {
              content: 'errors.times.comego.breakBetweenWorkingDays',
              args: { breakBetweenWorkingDays: error.value },
            };
          if (error.type === 'maxHours')
            return { content: 'errors.times.comego.dailyMaxHours', args: { dailyMaxHours: error.value } };
          return null;
        },
        minlength: (error, ctrl) => ({
          content: 'errors.minlength',
          args: { field: aliasName, length: error.requiredLength },
        }),
        maxlength: (error, ctrl) => ({
          content: 'errors.maxlength',
          args: { field: aliasName, length: error.requiredLength },
        }),
        sameType: (error, ctrl) => {
          return { content: 'Subsequent entries must not be the same type', args: {} };
        },
        missingType: (error, ctrl) => {
          return { content: 'Entries are missing a stop entry', args: {} };
        },
        invalidType: (error, ctrl) => {
          return { content: 'Before last type must not be of type "pause"', args: {} };
        },
        intervening: (error, ctrl) => {
          return { content: 'errors.times.comego.intervening', args: {} };
        },
      },
      (key) => key,
      {
        initialValidate: false,
      },
    );
  }
  readonly postDiffUser$ = combineLatest([
    this.group.valueChanges.pipe(
      map((x) => x?.user?.id),
      distinctUntilChanged(),
    ),
    this.userSettingsQuery.select(),
  ]).pipe(map(([uid, user]) => uid && uid !== user.id));

  readonly currentDateHasSchedule$ = combineLatest([this.group.valueChanges, this.userSettingsQuery.select()]).pipe(
    map(([value, user]) => {
      if (!value?.date) return null;
      const sched = getActiveSchedule({ ...user, id: value.user?.id || user.id }, value.date);
      return sched?.enabled && !!sched.items.find((x) => x.day === startOfDay(value.date).getUTCDay() && x.enabled);
    }),
  );
  readonly hiddenFields = createRxValue<{ [key: string]: boolean }>({});
  readonly canAddTime = createRxValue(true);
  readonly enabledTypes = createRxValue<{ [K in ComegoTimeType]?: boolean }>({
    absence: true,
    pause: true,
    work: true,
  });
  ngOnInit(): void {
    this.ref.updateSize('560px');
    this.ref.addPanelClass(['mat-dialog-vanilla', 'mat-dialog-relative']);
    const user = this.userSettingsQuery.getValue();
    this.group.patchValue({
      user: user?.workspace?.users?.find((u) => u.id === user.id),
    });
    if (this.data?.hiddenFields?.length)
      this.hiddenFields.next(this.data.hiddenFields.reduce((acc, r) => ({ ...acc, [r]: true }), {}));
    this.group.updateValueAndValidity();
    let error: any = this.data.error;
    if (this.data.initialTimes?.length) {
      if (isBreakRuleError(error)) pushError(error.message, error.args);
      error = null;
      nextTick(() => {
        this.data.initialTimes.forEach((t) => this.addTimeRange(t.type, t.start, t.end, false));
        this.group.controls.times.updateValueAndValidity();
      });
    } else if (!this.data?.time)
      nextTick(() => {
        // trigger validation after init
        this.addTime();
      });
    else {
      const {
        time: { value: time },
      } = this.data;
      nextTick(() => {
        this.group.controls.times.push(
          new FormGroup<ComeGoTimeControl>({
            start: new FormControl(format(new Date(time.start), 'HH:mm')),
            end: new FormControl(format(new Date(time.end || Date.now()), 'HH:mm')),
            type: new FormControl(time.type),
            user: new FormControl(time.user),
            timeZone: new FormControl(time.timeZone),
          }),
        );
        this.group.controls.times.updateValueAndValidity();
      });
    }
    if (error) {
      nextTick(() => {
        this.error$.next(error);
        if (!this.data.disabled && isWorkingHoursError(error)) this.checkValueOnceChange(this.group.value.times);
        if (this.data.disabled) {
          this.group.markAsPristine();
          this.group.disable();
        }
      });
    }
    if (!this.data.disabled && this.data.disabledFields?.length)
      nextTick(() => {
        this.data.disabledFields.forEach((x) => {
          if (this.group.controls[x]?.enabled) {
            this.group.controls[x].disable();
          } else {
            if (x === 'time.type') this.group.controls.times.controls.forEach((t) => t.controls.type.disable());
            if (x === 'time') {
              this.group.controls.times.controls.forEach((t) => t.disable());
              this.group.controls.times.disable();
            }
            if (x === 'time.add') this.canAddTime.next(false);
          }
        });
      });

    if (this.data.allowedTimeTypes?.length) {
      this.enabledTypes.next(this.data.allowedTimeTypes.reduce((acc, r) => ({ ...acc, [r]: true }), {}));
    }
    this.group.valueChanges
      .pipe(
        untilDestroyed(this),
        map((x) => x?.date),
        distinctUntilChangedJson(),
      )
      .subscribe(() => this.group.controls.times.updateValueAndValidity());
  }

  resetToSchedule() {
    const { date, user: selectedUser } = this.group.value;
    const user = this.userSettingsQuery.getValue();
    const times = generateTimeTableFromSchedule({ ...user, id: (selectedUser || user).id }, date);
    this.group.controls.times.clear({ emitEvent: true });
    this.group.patchValue({
      times: [],
    });
    if (times)
      times.forEach((x) =>
        this.group.controls.times.push(
          new FormGroup({
            type: new FormControl(x.type || 'work'),
            start: new FormControl(x.start!),
            end: new FormControl(x.end!),
            user: new FormControl(null),
          }),
        ),
      );
    this.group.updateValueAndValidity();
  }
  openUserPicker() {
    const user = this.group.value?.user;
    if (!user) return;
    this.dialog
      .open(UserSinglePickerDialogComponent, {
        data: <UserSinglePickerDialogData>{
          selected: this.group.value?.user,
          filter: ({ selected, ...x }) => {
            const status = ((user) => parseSubscriptionAsStatus(user.workspace, user))(
              this.userSettingsQuery.getValue(),
            );
            return ((x.has_license || status.isTrial) && !x.removed) || selected;
          },
        },
      })
      .afterClosed()
      .subscribe((user) => {
        if (user)
          this.patchValue({
            user,
          });
      });
  }
  calculateDuration(ctrl: TimesControl, ctrlIndex: number) {
    const value = ctrl.getRawValue();
    if (!value) return null;
    const start = coerceTimeFormat(value.start!).getTime(),
      end = coerceTimeFormat(value.end!).getTime();
    if (end < start) return null;
    return (end - start) / 1000;
  }
  get groupValue() {
    return this.group.getRawValue();
  }
  addTimeRange(type: ComegoTimeType, start: Date, end: Date, emitChangesOnAdd: boolean = true) {
    const isDisabledControl = (name: string) => !!this.data.disabledFields?.find((x) => x === name);
    this.group.controls.times.push(
      new FormGroup<ComeGoTimeControl>({
        start: new FormControl(format(start, 'HH:mm')),
        end: new FormControl(format(end, 'HH:mm')),
        type: new FormControl({ value: type, disabled: isDisabledControl('time.type') }),
        user: new FormControl(null),
      }),
      { emitEvent: false },
    );
    if (emitChangesOnAdd) this.group.controls.times.updateValueAndValidity();
  }
  addTime(type?: ComegoTimeType, duration?: number, reversi?: boolean) {
    const timeValues = this.group.controls.times.getRawValue();
    const prevControl = timeValues[timeValues.length - 1];
    const prevType = prevControl?.type || 'work';
    const isDisabledControl = (name: string) => !!this.data.disabledFields?.find((x) => x === name);
    const nextType = type ?? (!prevControl ? 'work' : prevType === 'work' ? 'pause' : 'work'),
      prevTime =
        (prevControl && coerceTimeFormat(prevControl?.end)) || roundToNearestMinutes(new Date(), { nearestTo: 15 }),
      nextTime =
        (duration &&
          clampDate(addMilliseconds(prevTime.getTime(), duration), { start: prevTime, end: endOfDay(prevTime) })) ||
        addMinutes(prevTime.getTime(), 30);
    if (this.data.allowedTimeTypes && !this.data.allowedTimeTypes?.find((x) => x === nextType)) return;
    if (reversi)
      this.group.controls.times.insert(
        0,
        new FormGroup<ComeGoTimeControl>({
          end: new FormControl(format(nextTime, 'HH:mm')),
          type: new FormControl({ value: nextType, disabled: isDisabledControl('time.type') }),
          start: new FormControl(format(prevTime, 'HH:mm')),
          user: new FormControl(null),
        }),
        { emitEvent: false },
      );
    else
      this.group.controls.times.push(
        new FormGroup<ComeGoTimeControl>({
          end: new FormControl(format(nextTime, 'HH:mm')),
          type: new FormControl({ value: nextType, disabled: isDisabledControl('time.type') }),
          start: new FormControl(format(prevTime, 'HH:mm')),
          user: new FormControl(null),
        }),
        { emitEvent: false },
      );
    this.group.controls.times.updateValueAndValidity();
  }
  deleteItem(index: number) {
    this.group.controls.times.removeAt(index);
    this.group.controls.times.controls.forEach((x) => x.updateValueAndValidity());
    this.group.updateValueAndValidity();
  }
  patchValue(data: Partial<typeof this.group.value> = {}, options?: Parameters<typeof this.group.patchValue>[1]) {
    const user = this.userSettingsQuery.getValue();
    if (data.user === null) data.user = user.workspace.users.find((x) => x.id === user.id);
    return this.group.patchValue(data, options);
  }
  private checkValueOnceChange(value: any) {
    this.group.valueChanges
      .pipe(
        untilDestroyed(this),
        map((x) => stringify(x.times) === stringify(value)),
        tap((x) => log.debug('break rule check', x)),
        takeWhile((x) => x, true),
      )
      .subscribe((x) => !x && this.error$.next(null));
  }
  isFieldShown(name: string) {
    return !this.hiddenFields.value?.[name];
  }
  async submit() {
    const { times, user, date: now, name } = this.group.getRawValue();
    if (!times.length) return;
    this.isLoading.update(true);
    const createTimes = times.reduce((acc, r) => {
      const start = coerceTimeFormat(r.start, now),
        end = coerceTimeFormat(r.end, now);
      acc.push({ ...r, start, end, user, name });
      return acc;
    }, []);
    if (this.data?.time?.deleteOriginTime && this.data.time.value?.id) {
      await this.comegoService.delete(this.data.time.value);
    }
    await this.comegoService
      .add(...createTimes)
      .then(this.recordService.handleWorkingHourSuccess.bind(this.recordService))
      .catch(async (err: HttpErrorResponse) => {
        this.isLoading.update(false);
        if (isWorkingHoursError(err.error)) {
          this.error$.next(parseErrorResponse(err));
          this.checkValueOnceChange(times);
        } else {
          pushError(err);
        }
        return Promise.reject(err);
      })
      .then((times) => {
        this.ref.close(times);
      });
    this.isLoading.update(false);
  }

  openCalPicker() {
    return this.dialog
      .open(TimeDatePickerComponent, {
        data: <TimeDatePickerConfig>{
          selectedDate: new Date(this.group.value.date),
        },
      })
      .afterClosed()
      .pipe(filter((x) => x && isValid(x)))
      .subscribe((x) => {
        this.patchValue({
          date: x,
        });
      });
  }
  selectInput(ev: Event, timeInput: ElRefDirective) {
    const el: HTMLElement = timeInput.elementRef.nativeElement;
    if (!el) {
      return;
    }
    const input = el.querySelector('input');
    if (input) {
      ev.preventDefault();
      input.select();
    }
  }
}
